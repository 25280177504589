import React, { useState, useEffect } from "react";
import PageHeader from "Components/Common/PageHeader";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Badge,
    Col,
    Row,
    Button,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import { SmartSelect } from "Components/Common/SmartSelect";
import { SmartInput } from "Components/Common/SmartInput";
import { SmartCurrency } from "Components/Common/SmartCurrency";
import { SmartDatePicker } from "Components/Common/SmartDatePicker";
import { MeetingCancelForm } from "./MeetingCancelForm";
import {
    useUpdateProspect,
    useFetchReferralTypes,
    useFetchMarketingSource,
    useFetchMeetings,
    useFetchEventLocations,
    useFetchEvents,
    useFetchPresenters,
    useUpdateProspectCancel,
    useUpdateProspectResult,
    useUpdateProspectReactive
} from "api";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useFetchProspectsById, useFetchProspectResultsById } from "api";

const _ = require("lodash");

const AdvisorProspectDetail = () => {
    const { id } = useParams();

    const updateProspectMutation = useUpdateProspect();
    const updateProspectCancel = useUpdateProspectCancel();
    const updateProspectReactive = useUpdateProspectReactive();
    const updateProspecResultMutation = useUpdateProspectResult();
    const { data } = useFetchProspectsById(id ? parseInt(id) : 0);
    const { data: results } = useFetchProspectResultsById(
        id ? parseInt(id) : 0
    );

    const { data: events, isLoading: event_loading } = useFetchEvents();
    const { data: event_types, isLoading: event_type_loading } =
        useFetchReferralTypes();
    const { data: marketing_sources, isLoading: market_source_loading } =
        useFetchMarketingSource();
    const { data: meetings, isLoading: meeting_type_loading } =
        useFetchMeetings();
    const { data: event_locations, isLoading: event_location_loading } =
        useFetchEventLocations();
    const { isLoading: advisors_loading, data: advisors } =
        useFetchPresenters();
    const [cancelModal, setCancelModal] = useState(false);
    const [ confirmModal, setConfirmModal] = useState(false);
    const [remarks, setRemarks] = useState("");

    const history = useNavigate();

    const initialValues = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        spouseFirstName: data?.spouseFirstName,
        spouseLastName: data?.spouseLastName,
        event: data?.event,
        marketingSource: data?.event?.marketingSource,
        eventType: data?.eventType,
        location: data?.event?.location,
        advisor: data?.advisor,
        newMoney: data?.newMoney,
        meetingDate: data?.meetingDate,
        meeting: data?.meeting,
        remarks: "",
    };

    const validationSchema = Yup.object({
        lastName: Yup.string().required("Please Input Last Name"),
        firstName: Yup.string().required("Please Input First Name"),
        // spouseLastName: Yup.string().required("Please Input Spouse Last Name"),
        // spouseFirstName: Yup.string().required("Please Input Spouse First Name"),
        event: Yup.object().nullable().notRequired(),
        marketingSource: Yup.object().nullable().notRequired(),
        eventType: Yup.object().nullable().notRequired(),
        location: Yup.object().nullable().notRequired(),
        advisor: Yup.object().required("Please Input Advisor"),
        meetingDate: Yup.date().required("Please Select Event Date"),
        meeting: Yup.object().required("Please Input Meeting Type"),
    });

    useEffect(() => {
        if (results && results.length > 0) {
            let newRemarks = "";
            results.forEach((result) => {
                if (result.remarks !== "" && result.remarks !== null)
                    newRemarks += result.remarks + "\n";
            });
            setRemarks(newRemarks);
        }
    }, [results]);

    const handleSubmit = (values) => {
        let updated = _.merge({}, data, values);

        updateProspectMutation.mutate(updated);

        let updatedResult = _.merge({}, results[results.length - 1], {
            remarks: values.remarks,
        });
        updateProspecResultMutation.mutate(updatedResult);
    };

    const handleFirstMeeting = () => {
        updateProspectCancel.mutate({ id: id });
    };

    const handleReactive = () => {      
        toggleConfirmModal();  
    }

    const handleReactiveSubmit = () => {      
        updateProspectReactive.mutate({id : id});
    }

    const handleSecondMeeting = () => {
        toggleModal();
    };

    const handleList = () => {
        history(-1);
    };

    const toggleModal = () => {
        setCancelModal(!cancelModal);
    };

    const toggleConfirmModal = () => {
        setConfirmModal(!confirmModal)
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <PageHeader title="Propsect Detail" />
                {data && (
                    <Formik
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                        initialValues={{ ...initialValues, remarks }}
                        onSubmit={(values: any) => handleSubmit(values)}
                    >
                        {({ resetForm, setFieldValue, values }) => (
                            <Form>

                                <Row className="mb-4">
                                    <Row className="mb-4">
                                        <h4 className="text-pink">
                                            Prospect Details
                                        </h4>
                                    </Row>
                                    <Row className="mb-2 px-md-4">
                                        <SmartInput
                                            type="text"
                                            name="firstName"
                                            label="First Name"
                                            size="6"
                                        />
                                        <SmartInput
                                            type="text"
                                            name="lastName"
                                            label="Last Name"
                                            size="6"
                                        />
                                    </Row>
                                    <Row className="mb-2 px-md-4">
                                        <SmartInput
                                            type="text"
                                            name="spouseFirstName"
                                            label="Spouse First Name"
                                            size="6"
                                        />
                                        <SmartInput
                                            type="text"
                                            name="spouseLastName"
                                            label="Spouse Last Name"
                                            size="6"
                                        />
                                    </Row>
                                    {data?.event && (
                                        <Row className="mb-2 px-md-4">
                                            <SmartSelect
                                                isDisabled={event_loading}
                                                isLoading={event_loading}
                                                isClearable={true}
                                                options={events}
                                                label="Event Name"
                                                name="event"
                                                size={6}
                                            />
                                            <SmartSelect
                                                isDisabled={
                                                    market_source_loading
                                                }
                                                isLoading={
                                                    market_source_loading
                                                }
                                                isClearable={true}
                                                options={marketing_sources}
                                                label="Marketing Source"
                                                name="marketingSource"
                                                optionLabelFn={(option) =>
                                                    option.name
                                                }
                                                size="6"
                                            />
                                        </Row>
                                    )}
                                    <Row className="mb-2 px-md-4">
                                        {!data?.event && (
                                            <SmartSelect
                                                isDisabled={event_type_loading}
                                                isLoading={event_type_loading}
                                                isClearable={true}
                                                options={event_types}
                                                label="Referral Type"
                                                name="eventType"
                                                size="6"
                                            />
                                        )}
                                        {data?.event && (
                                            <SmartSelect
                                                isDisabled={
                                                    event_location_loading
                                                }
                                                isLoading={
                                                    event_location_loading
                                                }
                                                isClearable={true}
                                                options={event_locations}
                                                label="Event Location"
                                                name="location"
                                                size="6"
                                            />
                                        )}
                                    </Row>
                                </Row>
                                <Row>
                                    <Row className="mb-4">
                                        <h4 className="text-pink">
                                            Prospect Status &nbsp;
                                            {data?.isActive ? (
                                                <Badge color="success">
                                                    Active
                                                </Badge>
                                            ) : (
                                                <Badge color="danger">
                                                    Closed
                                                </Badge>
                                            )}
                                        </h4>
                                    </Row>
                                    <Row className="mb-2 px-md-4">
                                        <SmartSelect
                                            isDisabled={advisors_loading}
                                            isLoading={advisors_loading}
                                            isClearable={true}
                                            options={advisors}
                                            label="Advisor"
                                            name="advisor"
                                            optionLabelFn={(option) =>
                                                option.fullName !== "null null"
                                                    ? option.fullName
                                                    : option.username
                                            }
                                            size="6"
                                        />
                                        <SmartCurrency
                                            size="6"
                                            label="Investible Assets"
                                            name="newMoney"
                                        />
                                    </Row>
                                    <Row className="mb-2 px-md-4">
                                        {/* <Col sm="12" md="6">
                                        <Label className="form-label" for="meetingDate">Meeting Date</Label>
                                        <Input className="form-control" name="meetingDate" type="date" value={data?.meetingDate} />
                                    </Col> */}
                                        <SmartDatePicker
                                            name="meetingDate"
                                            label="Meeting Date"
                                            size="6"
                                        />
                                        <SmartSelect
                                            isDisabled={true}
                                            isLoading={meeting_type_loading}
                                            isClearable={true}
                                            options={meetings}
                                            label="Meeting Type"
                                            name="meeting"
                                            size="6"
                                        />
                                    </Row>
                                    <Row className="mb-2 px-md-4">
                                        <SmartInput
                                            name="remarks"
                                            label="Remarks"
                                            size="12"
                                            as="textarea"
                                        />
                                    </Row>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-center mt-5 mb-2">
                                            {data?.isActive && (
                                                <Button
                                                    type="submit"
                                                    color="success"
                                                    className="save-user"
                                                >
                                                    <i className="bx bx-save"></i>{" "}
                                                    &nbsp;Save
                                                </Button>
                                            )}
                                            {data?.status ===
                                                "FIRST_MEETING" && (
                                                <Button
                                                    color="danger"
                                                    className="ms-2"
                                                    onClick={() =>
                                                        handleFirstMeeting()
                                                    }
                                                >
                                                    <i className="bx bx-save"></i>{" "}
                                                    &nbsp;Meeting as Cancelled
                                                </Button>
                                            )}
                                            {data?.status === "CANCELLED_1ST" && (
                                                <Button 
                                                    color="success" 
                                                    className="ms-2" 
                                                    onClick={() => handleReactive()}
                                                >
                                                    <i className="bx bx-save"></i>{" "}
                                                    &nbsp;Reactivate
                                                </Button>
                                            )}
                                            {data?.status ===
                                                "SECOND_MEETING" && (
                                                <Button
                                                    color="danger"
                                                    className="ms-2"
                                                    onClick={() =>
                                                        handleSecondMeeting()
                                                    }
                                                >
                                                    <i className="bx bx-save"></i>{" "}
                                                    &nbsp;Meeting as Cancelled
                                                </Button>
                                            )}
                                            <Button
                                                color="danger"
                                                className="ms-2"
                                                onClick={() => handleList()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                )}

            </div>
            <Modal isOpen={cancelModal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                    {data?.meeting?.name} Result : {data?.fullName}
                </ModalHeader>
                <ModalBody>
                    <MeetingCancelForm
                        current={data?.id}
                        rtoggle={toggleModal}
                    />
                </ModalBody>
            </Modal>
            <Modal isOpen={confirmModal} toggle={toggleConfirmModal}>
                <ModalHeader toggle={toggleConfirmModal}>
                    Are you sure you want to Reactivate?
                </ModalHeader>
                <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button color="success" onClick={handleReactiveSubmit}>
                        Confirm
                    </Button>&nbsp;
                    <Button color="danger" onClick={toggleConfirmModal}>
                        Cancel
                    </Button>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default AdvisorProspectDetail;
