import React, { useEffect, useState } from "react";

// Redux
import { Link } from "react-router-dom";
import { Row, Col, CardBody, Card, Container, Form, Input, Label, FormFeedback, Alert } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import logoimage from "../../assets/images/logo.webp";
import lightlogo from "../../assets/images/logo-light.svg";
import background from "../../assets/images/login-page.webp";

//import thunk
import { resetLoginMsgFlag } from "slices/auth/login/thunk";

import withRouter from "Components/Common/withRouter";
import { createSelector } from 'reselect';
import { useUserLogin } from "api";

const Login = (props: any) => {
  const [show, setShow] = useState(false);
  const dispatch: any = useDispatch();
  const loginMutation = useUserLogin()
  //meta title
  document.title = "Login | Ridgeline Metrics Dashboard";


  const selectProperties = createSelector(
    (state: any) => state.Login,
    (error) => error
  );

  const { error } = useSelector(selectProperties);

  // Form validation 
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values: any) => {
      // dispatch(loginuser(values, props.router.navigate));
      // console.log('submitted', values)
      loginMutation.mutate(values)
    }
  });


  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag())
      }, 3000);
    }
  }, [dispatch, error])

  return (
    <React.Fragment>
      <div className="account-pages" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '100vh' }}>
        <div style={{ maxWidth: '90% !important', width: '90% !important', margin: '0 60px' }}>
          <Row className="justify-content-center" style={{ paddingTop: '100px' }}>
            <Col lg={6} md={6} xl={6}>
              <img src={logoimage} alt="Ridgeline" className="img-fluid" style={{ width: '100%' }}></img>
              <h3 style={{ color: 'white', marginTop: '12px', marginLeft: '12px' }}>Powered By ASHWOOD METRICS</h3>
            </Col>
            <Col lg={6} md={6} xl={6}>
              <Row className="justify-content-center">
                <Col md={10} lg={10} xl={10}>
                  <Card className="overflow-hidden" style={{
                    background: 'transparent',
                    opacity: '79%',
                    backgroundColor: 'rgb(93, 104, 104)',
                    borderRadius: '24px'
                  }}>
                    {/* <div className="bg-primary bg-soft">
                      <Row>
                        <Col className="col-7">
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Welcome Back !!</h5>
                            <p>Sign in to continue to RMD.</p>
                          </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div> */}
                    <CardBody className="pt-0">
                      {/* <div className="auth-logo">
                        <Link to="/" className="auth-logo-light">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={lightlogo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                        <Link to="/" className="auth-logo-dark">
                          <div className="avatar-md profile-user-wid mb-4">
                            <span className="avatar-title rounded-circle bg-light">
                              <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                              />
                            </span>
                          </div>
                        </Link>
                      </div> */}
                      <div className="p-2">
                        <Form className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mt-4 mb-4 col-md-7 col-lg-7 col-xl-7">
                            {error ? <Alert color="danger">{error}</Alert> : null}
                            <Label className="form-label" style={{ fontSize: '24px', color: 'rgb(160, 0, 16)', marginLeft: '16px', fontWeight: '700' }}>USERNAME:</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter username"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username && validation.errors.username ? true : false
                              }
                              style={{ backgroundColor: 'white', borderRadius: '16px' }}
                            />
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                            ) : null}
                          </div>

                          <div className="mt-4 mb-4 col-md-7 col-lg-7 col-xl-7">
                            <Label className="form-label" style={{ fontSize: '24px', color: 'rgb(160, 0, 16)', marginLeft: '16px', fontWeight: '700' }}>PASSWORD:</Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={show ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password && validation.errors.password ? true : false
                                }
                                style={{ borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }}
                              />
                              <button onClick={() => setShow(!show)} className="btn btn-light " type="button" id="password-addon" style={{ borderTopRightRadius: '16px', borderBottomRightRadius: '16px' }}>
                                <i className="mdi mdi-eye-outline"></i></button>
                            </div>
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>

                          {/* <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div> */}

                          <div className="d-grid col-md-4 col-lg-4 col-xl-4">
                            <button
                              className="btn btn-block "
                              type="submit"
                              style={{ borderRadius: '24px', backgroundColor: 'white', fontSize: '24px', fontWeight: '700', marginTop: '32px', marginBottom: '32px' }}
                            >
                              LOGIN
                            </button>
                          </div>
                          {/* 
                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-primary text-white border-primary"
                              onClick={(e: any) => {
                                e.preventDefault();
                                socialResponse("facebook")
                              }}
                            >
                              <i className="mdi mdi-facebook" />
                            </Link>
                          </li>{" "}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-info text-white border-info"
                            >
                              <i className="mdi mdi-twitter" />
                            </Link>
                          </li>{" "}
                          <li className="list-inline-item">
                            <Link
                              to="#"
                              className="social-list-item bg-danger text-white border-danger"
                              onClick={(e: any) => {
                                e.preventDefault();
                                socialResponse("google")
                              }}
                            >
                              <i className="mdi mdi-google" />
                            </Link>
                          </li>
                        </ul>
                      </div> */}

                          {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" /> Forgot your
                          password?
                        </Link>
                      </div> */}
                        </Form>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    {/* <p>
                  Don&apos;t have an account ?{" "}
                  <Link
                    to="/register"
                    className="fw-medium text-primary"
                  >
                    {" "}
                    Signup now{" "}
                  </Link>
                </p> */}
                    {/* <p>
                      © {new Date().getFullYear()} RMD. Crafted with{" "}
                      <i className="mdi mdi-heart text-danger" /> by Ridgeline
                    </p> */}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <div style={{
            position: 'absolute',
            bottom: '20px',
            color: 'white',
            left: '20px'
          }}>
            <h4>RIDGELINE is the intellectual property of AHSWOOD METRICS</h4>
          </div> */}
        </div>
      </div >
    </React.Fragment >
  );
};

export default withRouter(Login);
