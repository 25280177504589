import { APIClient } from "helpers/api_helper";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { errorToast, successToast } from "Components/Common/Toast";
import { useNavigate } from "react-router-dom";

const api = new APIClient();
const serverUrl =
    process.env.REACT_APP_SERVER_URL || process.env.REACT_APP_LOCAL_SERVER_URL;
const url = `${serverUrl}/marketing/prospects`;
const key = "prospects";
const messageKey = "Prospect";

const fetchObjects = async () => await api.get(`${url}/all`, null);
const createObject = async (obj) => await api.create(url, obj);
const updateObject = async (obj) => await api.put(`${url}/${obj.id}`, obj);
const updateProspectCancel = async (obj) =>
    await api.get(`${url}/cancel/${obj.id}`, null);
const updateProspectReactive = async (obj) => 
    await api.get(`${url}/reactive/${obj.id}`, null);
const updateProspectSecondCancel = async (obj) =>
    await api.get(`${url}/cancel_second/${obj.id}/${obj.flag}`, null);
const fetchResultObjects = async () => await api.get(`${url}/result`, null);
const fetchResultsById = async (prospectId) =>
    await api.get(`${url}/result/${prospectId}`, null);
const fetchObjectsByEvent = async (eventId) =>
    await api.get(`${url}/event/${eventId}`, null);
const fetchObjectsByEventAll = async () =>
    await api.get(`${url}/all_event`, null);
const fetchProspecStatistics = async (data) =>
    await api.get(`${url}/statistics`, data);

export const uploadProspectFile = async (data: any): Promise<any> => {
    const formData = new FormData();
    formData.append("file", data.file);
    return await api.upload(`${url}/upload?event=${data.id}`, formData);
};

export const useUploadProspectFile = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: uploadProspectFile,
        onSuccess: () => {
            successToast(`Prospect File Uploaded Successfully`);
            queryClient.invalidateQueries([key]);
        },
        onError: (error) => {
            errorToast(error);
        },
    });
};

export const useFetchProspectMetrics = (data) => {
    return useQuery(
        [key, "statistics", data],
        () => fetchProspecStatistics(data),
        {
            enabled: !!data?.type,
        }
    );
};

export const useFetchProspects = () => {
    return useQuery([key], fetchObjects);
};

export const useFetchEventProspects = () => {
    return useQuery([key], fetchObjectsByEventAll);
};

export const useFetchProspectResults = () => {
    return useQuery([key, "result"], fetchResultObjects);
};

export const useFetchProspectResultsById = (prospectId) => {
    return useQuery([key, "result", prospectId], () =>
        fetchResultsById(prospectId)
    );
};

export const useFetchProspectsByEvent = (eventId) => {
    return useQuery(
        [key, "event", eventId],
        () => fetchObjectsByEvent(eventId),
        {
            enabled: !!eventId,
        }
    );
};

export const useCreateProspect = () => {
    const queryClient = useQueryClient();
    const history = useNavigate();
    return useMutation(createObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key]);
            successToast(`${messageKey} created Successfully`);
            history(-1);
        },
        onError: (error) => {
            errorToast(error);
        },
    });
};

export const useUpdateProspect = () => {
    const queryClient = useQueryClient();
    const history = useNavigate();
    return useMutation(updateObject, {
        onSuccess: () => {
            queryClient.invalidateQueries([key]);
            successToast(`${messageKey} updated Successfully`);
            history(-1);
        },
        onError: (error) => {
            errorToast(error);
        },
    });
};

export const useUpdateProspectCancel = () => {
    const queryClient = useQueryClient();
    const history = useNavigate();
    return useMutation(updateProspectCancel, {
        onSuccess: () => {
            queryClient.invalidateQueries([key]);
            successToast(`${messageKey} updated Successfully`);
            history(-1);
        },
        onError: (error) => {
            errorToast(error);
        },
    });
};

export const useUpdateProspectReactive = () => {
    const queryClient = useQueryClient();
    const history = useNavigate();
    return useMutation(updateProspectReactive, {
        onSuccess: () => {
            queryClient.invalidateQueries([key])
            successToast(`${messageKey} updated Successfully`);
            history(-1);
          },
          onError: (error) => {
            errorToast(error);
          }
    });
}

export const useUpdateProspectSecondCancel = () => {
    const queryClient = useQueryClient();
    const history = useNavigate();
    return useMutation(updateProspectSecondCancel, {
        onSuccess: () => {
            queryClient.invalidateQueries([key]);
            successToast(`${messageKey} updated Successfully`);
            history(-1);
        },
        onError: (error) => {
            errorToast(error);
        },
    });
};
